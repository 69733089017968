import { createPopup } from "@typeform/embed";
import { useEffect, useState } from "react";
import Switch from "react-switch";
import "./App.css";
import { AlertContainer } from "./components/alerts/AlertContainer";
import { Grid } from "./components/grid/Grid";
import { Keyboard } from "./components/keyboard/Keyboard";
import { InfoModal } from "./components/modals/InfoModal";
import { MAX_CHALLENGES, MAX_WORD_LENGTH } from "./constants/settings";
import { CORRECT_WORD_MESSAGE } from "./constants/strings";
import { useAlert } from "./context/AlertContext";
import BG from "./flappy-bird/img/BG.png";
import birdb0 from "./flappy-bird/img/bird/b0.png";
import birdb1 from "./flappy-bird/img/bird/b1.png";
import birdb2 from "./flappy-bird/img/bird/b2.png";
import botpipe from "./flappy-bird/img/botpipe.png";
import getready from "./flappy-bird/img/getready.png";
import go from "./flappy-bird/img/go.png";
import ground from "./flappy-bird/img/ground.png";
import {
  default as tapt0,
  default as tapt1,
} from "./flappy-bird/img/tap/taptostart.png";
import toppipe from "./flappy-bird/img/toppipe.png";
import correctword from "./flappy-bird/sfx/correct-word.mp3";
import die from "./flappy-bird/sfx/die.wav";
import flap from "./flappy-bird/sfx/flap.wav";
import hit from "./flappy-bird/sfx/hit.wav";
import score from "./flappy-bird/sfx/score.wav";
import start from "./flappy-bird/sfx/start.wav";
import { getWordOfDay, isWinningWord, unicodeLength } from "./lib/words";

const acceptedKeys = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const RAD = Math.PI / 180;

const App = () => {
  const { showError: showErrorAlert } = useAlert();
  const [currentGuess, setCurrentGuess] = useState("");
  const [guesses, setGuesses] = useState([]);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(true);
  const [easyGame, setEasyGame] = useState(false);

  useEffect(() => {
    getWordOfDay();
    if (localStorage.getItem("easyGameMode") === "true") {
      setEasyGame(true);
    }
  }, []);

  const trackClick = (url, btnText) => {
    fetch("https://www.samedayskunkworks.com/api/analytics/addEvent", {
      method: "post",
      body: JSON.stringify({
        origin: window.location.href,
        destination: url,
        event: `${btnText}`,
      }),
    });
  };

  const handleSwitchChange = () => {
    const l = localStorage.getItem("easyGameMode");
    if (l === "true") {
      localStorage.setItem("easyGameMode", false);
      setEasyGame(false);
      window.location.reload();
    } else {
      localStorage.setItem("easyGameMode", true);
      setEasyGame(true);
      window.location.reload();
    }
  };

  useEffect(() => {
    const scrn = document.getElementById("canvas");
    const sctx = scrn.getContext("2d");
    scrn.tabIndex = 1;
    let canFlap = true;
    const easyGameLocalStorage = localStorage.getItem("easyGameMode");

    document.onkeydown = function keyDown(e) {
      if (acceptedKeys.includes(e.key)) {
        handleBird();
      }
    };

    const handleBird = () => {
      switch (state.curr) {
        case state.getReady:
          state.curr = state.Play;
          SFX.start.play();
          break;
        case state.Play:
          if (canFlap) {
            bird.flap();
          }
          break;
        case state.gameOver:
          state.curr = state.getReady;
          canFlap = true;
          bird.speed = 0;
          bird.y = 100;
          pipe.pipes = [];
          UI.score.curr = 0;
          SFX.played = false;
          break;
        default:
          break;
      }
    };

    let frames = 0;
    let dx = 2;
    const state = {
      curr: 0,
      getReady: 0,
      Play: 1,
      gameOver: 2,
    };
    const SFX = {
      start: new Audio(),
      flap: new Audio(),
      score: new Audio(),
      hit: new Audio(),
      die: new Audio(),
      correctWord: new Audio(),
      played: false,
    };
    const gnd = {
      sprite: new Image(),
      x: 0,
      y: 0,
      draw: function () {
        this.y = parseFloat(scrn.height - this.sprite.height);
        sctx.drawImage(this.sprite, this.x, this.y);
      },
      update: function () {
        if (state.curr !== state.Play) return;
        this.x -= dx;
        this.x = this.x % (this.sprite.width / 2);
      },
    };
    const bg = {
      sprite: new Image(),
      x: 0,
      y: 0,
      draw: function () {
        const y = parseFloat(scrn.height - this.sprite.height);
        sctx.drawImage(this.sprite, this.x, y);
      },
    };
    const pipe = {
      top: { sprite: new Image() },
      bot: { sprite: new Image() },
      gap: 200,
      moved: true,
      pipes: [],
      draw: function () {
        for (let i = 0; i < this.pipes.length; i++) {
          let p = this.pipes[i];
          sctx.drawImage(this.top.sprite, p.x, p.y);
          sctx.drawImage(
            this.bot.sprite,
            p.x,
            p.y + parseFloat(this.top.sprite.height) + this.gap
          );
        }
      },
      update: function () {
        if (state.curr !== state.Play) return;
        if (frames % 100 === 0) {
          this.pipes.push({
            x: parseFloat(scrn.width),
            y: -280 * Math.min(Math.random() + 1, 1.3),
          });
        }
        this.pipes.forEach((pipe) => {
          pipe.x -= dx;
        });

        if (this.pipes.length && this.pipes[0].x < -this.top.sprite.width) {
          this.pipes.shift();
          this.moved = true;
        }
      },
    };
    const bird = {
      animations: [
        { sprite: new Image() },
        { sprite: new Image() },
        { sprite: new Image() },
        { sprite: new Image() },
      ],
      rotatation: 0,
      x: 50,
      y: 100,
      speed: 0,
      gravity: localStorage.getItem("easyGameMode") === "false" ? 0.08 : 0.05,
      thrust: 3,
      frame: 0,
      draw: function () {
        let h = this.animations[this.frame].sprite.height;
        let w = this.animations[this.frame].sprite.width;
        sctx.save();
        sctx.translate(this.x, this.y);
        sctx.rotate(this.rotatation * RAD);
        sctx.drawImage(this.animations[this.frame].sprite, -w / 2, -h / 2);
        sctx.restore();
      },
      update: function () {
        let r = parseFloat(this.animations[0].sprite.width) / 2;
        switch (state.curr) {
          case state.getReady:
            this.rotatation = 0;
            this.y += frames % 10 === 0 ? Math.sin(frames * RAD) : 0;
            this.frame += frames % 10 === 0 ? 1 : 0;
            getWordOfDay();
            break;
          case state.Play:
            this.frame += frames % 5 === 0 ? 1 : 0;
            this.y += this.speed;
            this.setRotation();
            this.speed += this.gravity;
            if (this.y + r >= gnd.y || this.collisioned()) {
              state.curr = state.gameOver;
            }
            break;
          case state.gameOver:
            this.frame = 1;
            if (this.y + r < gnd.y) {
              this.y += this.speed;
              this.setRotation();
              this.speed += this.gravity * 2;
            } else {
              this.speed = 0;
              this.y = gnd.y - r;
              this.rotatation = 90;
              if (!SFX.played) {
                showErrorAlert(
                  CORRECT_WORD_MESSAGE(localStorage.getItem("solution")),
                  {
                    persist: false,
                    delayMs: 0,
                  }
                );
                SFX.die.play();
                SFX.played = true;
              }
            }
            break;
          default:
            break;
        }
        this.frame = this.frame % this.animations.length;
      },
      flap: function () {
        if (this.y > 0) {
          SFX.flap.play();
          this.speed = -this.thrust;
        }
      },
      setRotation: function () {
        if (this.speed <= 0) {
          this.rotatation = Math.max(
            -25,
            (-25 * this.speed) / (-1 * this.thrust)
          );
        } else if (this.speed > 0) {
          this.rotatation = Math.min(90, (90 * this.speed) / (this.thrust * 2));
        }
      },
      collisioned: function () {
        if (!pipe.pipes.length) return;
        let bird = this.animations[0].sprite;
        let x = pipe.pipes[0].x;
        let y = pipe.pipes[0].y;
        let r = bird.height / 4 + bird.width / 4;
        let roof = y + parseFloat(pipe.top.sprite.height - 10);
        let floor = roof + pipe.gap;
        let w = parseFloat(pipe.top.sprite.width);
        if (this.x + r >= x) {
          if (this.x + r < x + w) {
            if (this.y - r <= roof || this.y + r >= floor) {
              if (easyGameLocalStorage === "false") {
                SFX.hit.play();
                return true;
              }
            }
          } else if (pipe.moved) {
            UI.score.curr++;
            SFX.score.play();
            pipe.moved = false;
          }
        }
      },
    };
    const UI = {
      getReady: { sprite: new Image() },
      gameOver: { sprite: new Image() },
      tap: [{ sprite: new Image() }, { sprite: new Image() }],
      score: {
        curr: 0,
        best: 0,
      },
      x: 0,
      y: 0,
      tx: 0,
      ty: 0,
      frame: 0,
      draw: function () {
        switch (state.curr) {
          case state.getReady:
            this.y = parseFloat(scrn.height - this.getReady.sprite.height) / 2;
            this.x = parseFloat(scrn.width - this.getReady.sprite.width) / 2;
            this.tx = parseFloat(scrn.width - this.tap[0].sprite.width) / 2;
            this.ty =
              this.y + this.getReady.sprite.height - this.tap[0].sprite.height;
            sctx.drawImage(this.getReady.sprite, this.x, this.y);
            setGuesses([]);
            setCurrentGuess("");
            sctx.drawImage(this.tap[this.frame].sprite, this.tx, this.ty);
            break;
          case state.gameOver:
            this.y = parseFloat(scrn.height - this.gameOver.sprite.height) / 2;
            this.x = parseFloat(scrn.width - this.gameOver.sprite.width) / 2;
            this.tx = parseFloat(scrn.width - this.tap[0].sprite.width) / 2;
            this.ty =
              this.y + this.gameOver.sprite.height - this.tap[0].sprite.height;
            sctx.drawImage(this.gameOver.sprite, this.x, this.y);
            sctx.drawImage(this.tap[this.frame].sprite, this.tx, this.ty);
            break;
          default:
            break;
        }
        this.drawScore();
      },
      drawScore: function () {
        sctx.fillStyle = "#FFFFFF";
        sctx.strokeStyle = "#000000";
        switch (state.curr) {
          case state.Play:
            sctx.lineWidth = "2";
            sctx.font = "35px Squada One";
            sctx.fillText(this.score.curr, scrn.width / 2 - 5, 50);
            sctx.strokeText(this.score.curr, scrn.width / 2 - 5, 50);
            break;
          case state.gameOver:
            sctx.lineWidth = "2";
            sctx.font = "40px Squada One";
            let sc = `SCORE :     ${this.score.curr}`;
            try {
              this.score.best = Math.max(
                this.score.curr,
                localStorage.getItem("best")
              );
              localStorage.setItem("best", this.score.best);
              let bs = `BEST  :     ${this.score.best}`;
              sctx.fillText(sc, scrn.width / 2 - 80, scrn.height / 2 + 0);
              sctx.strokeText(sc, scrn.width / 2 - 80, scrn.height / 2 + 0);
              sctx.fillText(bs, scrn.width / 2 - 80, scrn.height / 2 + 30);
              sctx.strokeText(bs, scrn.width / 2 - 80, scrn.height / 2 + 30);
            } catch (e) {
              sctx.fillText(sc, scrn.width / 2 - 85, scrn.height / 2 + 15);
              sctx.strokeText(sc, scrn.width / 2 - 85, scrn.height / 2 + 15);
            }
            break;
          default:
            break;
        }
      },
      update: function () {
        if (state.curr === state.Play) return;
        this.frame += frames % 10 === 0 ? 1 : 0;
        this.frame = this.frame % this.tap.length;
      },
    };

    gnd.sprite.src = ground;
    bg.sprite.src = BG;
    pipe.top.sprite.src = toppipe;
    pipe.bot.sprite.src = botpipe;

    UI.gameOver.sprite.src = go;
    UI.getReady.sprite.src = getready;
    UI.tap[0].sprite.src = tapt0;
    UI.tap[1].sprite.src = tapt1;
    bird.animations[0].sprite.src = birdb0;
    bird.animations[1].sprite.src = birdb1;
    bird.animations[2].sprite.src = birdb2;
    bird.animations[3].sprite.src = birdb0;
    SFX.start.src = start;
    SFX.flap.src = flap;
    SFX.score.src = score;
    SFX.hit.src = hit;
    SFX.die.src = die;
    SFX.correctWord.src = correctword;

    gameLoop();

    function gameLoop() {
      update();
      draw();
      frames++;
      requestAnimationFrame(gameLoop);
    }

    function update() {
      bird.update();
      gnd.update();
      pipe.update();
      UI.update();
    }

    function draw() {
      sctx.fillStyle = "#30c0df";
      sctx.fillRect(0, 0, scrn.width, scrn.height);
      bg.draw();
      pipe.draw();

      bird.draw();
      gnd.draw();
      UI.draw();
    }

    window.addEventListener("message", (event) => {
      switch (event.data) {
        case "win":
          UI.score.curr += 20;
          SFX.correctWord.play();
          getWordOfDay();
          break;
        case "lostWordle":
          canFlap = false;
          break;
        default:
          break;
      }
    });
  }, []);

  const onChar = (value) => {
    document.dispatchEvent(new KeyboardEvent("keydown", { key: value }));

    if (
      unicodeLength(`${currentGuess}${value}`) <= MAX_WORD_LENGTH &&
      guesses.length < MAX_CHALLENGES
    ) {
      const currGuess = `${currentGuess}${value}`;
      setCurrentGuess(currGuess);
    }
  };

  const onDelete = () => {
    // setCurrentGuess(
    //   new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join("")
    // );
  };

  const onEnter = () => {
    const winningWord = isWinningWord(currentGuess);
    if (
      currentGuess.length === MAX_WORD_LENGTH &&
      guesses.length < MAX_CHALLENGES
    ) {
      setGuesses([...guesses, currentGuess]);
      setCurrentGuess("");

      if (winningWord) {
        setGuesses([]);
        window.postMessage("win");
        return;
      }

      if (guesses.length === MAX_CHALLENGES - 1) {
        if (localStorage.getItem("easyGameMode") === "false") {
          window.postMessage("lostWordle");
          showErrorAlert(
            CORRECT_WORD_MESSAGE(localStorage.getItem("solution")),
            {
              persist: false,
              delayMs: 0,
            }
          );
        } else {
          setGuesses([]);
          setCurrentGuess("");
        }
      }
    }
  };

  const handleCloseInfoModal = () => {
    setIsInfoModalOpen(false);
  };

  useEffect(() => {
    if (currentGuess.length === 5) {
      onEnter();
    }
  }, [currentGuess]);

  const openTypeForm = () => {
    const { toggle } = createPopup("PJAvghq3", {
      mode: "popup",
      opacity: 100,
      size: 100,
      iframeProps: {
        title: "Increase Human Agency with AE Studio",
      },
      transitiveSearchParams: true,
      medium: "snippet",
      hidden: {
        utm_source: "sds",
        utm_medium: "referral",
        utm_campaign: "flappybirdle",
        utm_content: "typeform-schedule-call",
        utm_term: "3ff5251a-e107-4d47-bfb8-b2962debd252",
      },
    });

    toggle();
  };

  return (
    <div className="pt-2 max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div
        className="switch-container"
        alt="No pipe collision, infinite worlde tries"
      >
        <Switch
          onChange={handleSwitchChange}
          checked={easyGame}
          className="switch"
        />
        Easy mode
      </div>

      <div className="flex items-center justify-center mb-10 logo">
        <img className="logo" src={"/logo.png"} alt="Logo" />
      </div>
      <div className="flex justify-center gap-10 content-container">
        <div className="logo-sm items-center justify-center">
          <img className="logo" src={"/logo.png"} alt="Logo" />
        </div>
        <div className="grid-container">
          <InfoModal
            isOpen={isInfoModalOpen}
            handleClose={handleCloseInfoModal}
          />
          <Grid
            guesses={guesses}
            currentGuess={currentGuess}
            isRevealing={false}
            currentRowClassName={""}
          />
          <Keyboard
            onChar={onChar}
            onDelete={onDelete}
            onEnter={onEnter}
            guesses={guesses}
            isRevealing={false}
          />
          <AlertContainer />
        </div>
        <div>
          <canvas id="canvas" width="276" height="414"></canvas>
        </div>
      </div>

      <footer>
        <div className="bg-white">
          <div className="mx-auto max-w-7xl px-6 py-24 lg:px-8">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="lg:col-span-5">
                <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
                  FAQ
                </h2>
                <p className="mt-4 text-base leading-7 text-gray-600">
                  Want to talk to us?{" "}
                  <button
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                    onClick={openTypeForm}
                  >
                    Schedule a call
                  </button>
                </p>
              </div>
              <div className="mt-10 lg:col-span-7 lg:mt-0">
                <dl className="space-y-10">
                  <div>
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                      OK, WHO MADE THIS MONSTROSITY???
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">
                      <a
                        href="https://ae.studio/same-day-skunkworks?utm_source=sds&utm_medium=referral&utm_campaign=flappybirdle&utm_content=about&utm_term=3ff5251a-e107-4d47-bfb8-b2962debd252"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() =>
                          trackClick(
                            "https://ae.studio/same-day-skunkworks?utm_source=sds&utm_medium=referral&utm_campaign=flappybirdle&utm_content=about&utm_term=3ff5251a-e107-4d47-bfb8-b2962debd252",
                            "AE Studio"
                          )
                        }
                      >
                        AE Studio
                      </a>
                      . We are a development, data science and design studio
                      that works closely with founders and executives to create
                      custom software, machine learning and BCI solutions.{" "}
                      {";)"}
                    </dd>
                  </div>
                  <div>
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                      Why is this so hard?
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">
                      We are sadists. JK. We're just testing people's
                      multitasking skills. Turn on Easy mode to ignore pipes.
                    </dd>
                  </div>
                  <div>
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                      How to play Flappy Birdle?
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">
                      You must type words to make the bird flap, try not to
                      crash. You get +1 point for every pipe and +20 points for
                      every correct word.
                    </dd>
                  </div>
                  <div>
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                      How did you make this?
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">
                      Well we’re actually really good at building complicated
                      tools, and in our free time we like to build simple things
                      like this to mess with people (and ourselves). We also
                      used{" "}
                      <a
                        href="https://github.com/cwackerfuss/react-wordle"
                        rel="noreferrer"
                        target="_blank"
                        onClick={() =>
                          trackClick(
                            "https://github.com/cwackerfuss/react-wordle",
                            "React wordle"
                          )
                        }
                      >
                        React Wordle
                      </a>{" "}
                      {"&"}{" "}
                      <a
                        href="https://github.com/aaarafat/JS-Flappy-Bird"
                        rel="noreferrer"
                        target="_blank"
                        onClick={() =>
                          trackClick(
                            "https://github.com/aaarafat/JS-Flappy-Bird",
                            "JS Flappy Bird"
                          )
                        }
                      >
                        JS Flappy Bird
                      </a>{" "}
                      to help out.
                    </dd>
                  </div>
                  <div>
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                      Does it have an end?
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">
                      Yes. When the bird crashes or you get tired 😝
                    </dd>
                  </div>
                  <div>
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                      Can I audition to be a bird in the game?
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">
                      Well, no. You’re most likely a human, in which case you
                      don’t really follow the design constraints of a pixelated
                      Birdle. Unless of course you aren’t a human, but then I’d
                      be more concerned about why a sentient life form that
                      isn’t human would be so eager to throw away one’s
                      existence on a mashed up game. You know what I mean?
                    </dd>
                  </div>
                  <div>
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                      I found a bug, how can I report?
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">
                      You can send us an{" "}
                      <a
                        href="mailto: jean.mayer@ae.studio"
                        onClick={() =>
                          trackClick(
                            "mailto: jean.mayer@ae.studio",
                            "Found a bug"
                          )
                        }
                      >
                        email
                      </a>
                      .
                    </dd>
                  </div>
                  <div>
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                      Are there more projects like this?
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">
                      Yes. Check out{" "}
                      <a
                        href="https://ae.studio/same-day-skunkworks?utm_source=sds&utm_medium=referral&utm_campaign=flappybirdle&utm_content=about&utm_term=3ff5251a-e107-4d47-bfb8-b2962debd252"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() =>
                          trackClick(
                            "https://ae.studio/same-day-skunkworks?utm_source=sds&utm_medium=referral&utm_campaign=flappybirdle&utm_content=about&utm_term=3ff5251a-e107-4d47-bfb8-b2962debd252",
                            "AE Studio's Same Day Skunkworks"
                          )
                        }
                      >
                        Same Day Skunkworks at AE Studio
                      </a>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;
