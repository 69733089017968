import { default as GraphemeSplitter } from "grapheme-splitter";
import {
  NOT_CONTAINED_MESSAGE,
  WRONG_SPOT_MESSAGE,
} from "../constants/strings";
import { VALID_GUESSES } from "../constants/validGuesses";
import { WORDS } from "../constants/wordlist";
import { getGuessStatuses } from "./statuses";

export const isWordInWordList = (word: string) => {
  return (
    WORDS.includes(localeAwareLowerCase(word)) ||
    VALID_GUESSES.includes(localeAwareLowerCase(word))
  );
};

export const isWinningWord = (word: string) => {
  const solution = localStorage.getItem("solution") || "";
  return solution === word;
};

// build a set of previously revealed letters - present and correct
// guess must use correct letters in that space and any other revealed letters
// also check if all revealed instances of a letter are used (i.e. two C's)
export const findFirstUnusedReveal = (word: string, guesses: string[]) => {
  if (guesses.length === 0) {
    return false;
  }

  const lettersLeftArray = new Array<string>();
  const guess = guesses[guesses.length - 1];
  const statuses = getGuessStatuses(guess);

  for (let i = 0; i < guess.length; i++) {
    if (statuses[i] === "correct" || statuses[i] === "present") {
      lettersLeftArray.push(guess[i]);
    }
    if (statuses[i] === "correct" && word[i] !== guess[i]) {
      return WRONG_SPOT_MESSAGE(guess[i], i + 1);
    }
  }

  // check for the first unused letter, taking duplicate letters
  // into account - see issue #198
  let n;
  for (const letter of word) {
    n = lettersLeftArray.indexOf(letter);
    if (n !== -1) {
      lettersLeftArray.splice(n, 1);
    }
  }

  if (lettersLeftArray.length > 0) {
    return NOT_CONTAINED_MESSAGE(lettersLeftArray[0]);
  }
  return false;
};

export const unicodeSplit = (word: string) => {
  return new GraphemeSplitter().splitGraphemes(word);
};

export const unicodeLength = (word: string) => {
  return unicodeSplit(word).length;
};

export const localeAwareLowerCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleLowerCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toLowerCase();
};

export const localeAwareUpperCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleUpperCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toUpperCase();
};

let changed = false;

export const getWordOfDay = () => {
  if (!changed) {
    const index = Math.floor(Math.random() * WORDS.length);

    localStorage.setItem("solution", localeAwareUpperCase(WORDS[index]));
    localStorage.setItem("solutionIndex", index.toString());
    changed = true;

    setTimeout(() => {
      changed = false;
    }, 100);
  }
};
